import axios from 'axios';
import {
  ElMessage,
  ElNotification
} from 'element-plus';
import {
  Session
} from '@/utils/storage';
const service = axios.create({
  baseURL: "https://api.iadge.org",
  timeout: 50000,
});
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["accept"] = "application/json";
    config.headers["Content-Type"] = "application/json";
    if (Session.get('token')) {
      config.headers["Authorization"] = "Bearer " + Session.get('token');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    if (Session.get('token')) {
      response.headers["Authorization"] = "Bearer " + Session.get('token');
    }
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.message.indexOf('timeout') != -1) {
      ElNotification({
        title: 'Error',
        message: '网络超时',
        type: 'error',
      });
    } else if (error.message == 'Network Error') {
      ElMessage.error('网络连接错误');
    } else {
      console.log(error.response, 123);
      if (error.response?.data) {
        for (const d in error.response.data.errors) {
          ElNotification({
            title: 'Error',
            message: `请求失败 !  ${error.response.data.errors[d]}`,
            type: 'error',
          });
        }
      }
    }
    return Promise.reject(error);
  }
);
export default service;
