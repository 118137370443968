<template>
  <div class="main">
    <div class="bk1">
      <img
        src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/head%403x.png"
        alt=""
      />
    </div>
    <div class="left">
      <img
        src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220609/iadge.svg"
        alt=""
        class="hand"
        @click="topath('/index')"
      />
      <div class="right">分会管理界面</div>
    </div>
    <div class="toright" v-if="$store.state.userinfo.id" @click="logout">
      {{ $store.state.userinfo.username }}<span>[退出登录]</span>
    </div>
  </div>
</template>
  
  <script>
import { Session } from "../utils/storage";
import { ElNotification } from "element-plus";
import { loginout } from "../api/twice";
export default {
  name: "",
  props: {
    msg: String,
  },
  methods: {
    topath(e) {
      console.log(e, 12345);
      this.$router.push(e);
    },
    async logout() {
      const res = await loginout();
      // console.log(res,9999);
      if (res.success) {
        this.$store.state.userinfo = null;
      }

      this.$store.state.login = false;
      Session.remove("token");
      this.login = false;
      ElNotification({
        title: "Success",
        message: "退出登录成功!正在为您切换到登录页.",
        type: "success",
      });
      setTimeout(() => {
        this.$router.push("/");
      }, 300);
    },
  },
  mounted() {
    console.log(this.$store.state.login, 899);
  },
  computed: {
    login() {
      if (Session.get("token")) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
  <style scoped lang="scss">
.main {
  height: 256px;
  // height: 23.6vh;
  //   width: 100%;
  display: flex;
  background: linear-gradient(168deg, #631f7a 0%, #d95347 40%, #e3cc30 100%);
  // background-image: url('https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/head_figure_line%402x.svg');
  border-radius: 0 0 80px 0px;
  align-items: center;
  padding: 0 100px;
  position: relative;
  justify-content: space-between;
  .bk1 {
    z-index: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    // max-width: 100%;
    // max-height: 100%;
    img {
      width: 100%;
    }
  }
  .left {
    z-index: 1;
    display: flex;
    align-items: center;
    img {
      width: 124px;
    }
    .right {
      z-index: 1;
      font-size: 48px;
      font-weight: bold;
      color: #ffffff;
      line-height: 56px;
      margin-left: 34px;
    }
  }
  .toright {
    z-index: 1;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    span {
      margin-left: 14px;
      cursor: pointer;
    }
  }
}
</style>
  